<template>
  <div class="container">
    <h4 class="text-center my-4">Посещаемость</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <section>
        <ul>
          <li>Если не появляется QR попробуйте обновить страницу (клавиша F5)</li>
          <li>Также проверьте свой логин</li>
          <li>Если не вышло проверьте расписание
            <router-link to="/schedule/my">Мое расписание</router-link>
          </li>
          <li>При дополнительных проблемах обращаться к Заведующим кафедрам</li>
        </ul>
      </section>

      <!-- QR section -->
      <section class="my-3">
        <div v-if="QRPath" class="text-center">
          <img :src="`https://back.uib.kz/${QRPath}`" height="250">
        </div>
        <div v-else>QR не сформирован</div>
      </section>
      <!-- end QR section -->


      <!-- Students section -->
      <section class="my-3">
        <div class="my-2">
          <Button icon="pi pi-refresh" label="Обновить список студентов"
                  class="p-button-sm p-button-rounded p-button-text"
                  :loading="loadingStudents"
                  @click="getStudents"/>
        </div>
        <PrimePreloader v-if="loadingStudents"/>
        <div v-else>
          <div v-if="students.length">
            <DataTable :value="students" showGridlines stripedRows responsiveLayout="scroll">
              <Column header="№" :style="{width: '60px'}">
                <template #body="{index}">
                  {{index+1}}
                </template>
              </Column>

              <Column field="barcode" header="Баркод"></Column>
              <Column header="ФИО">
                <template #body="{data}">
                  {{data.last_name}} {{data.first_name}} {{data.middle_name}}
                </template>
              </Column>

              <Column header="Посещение">
                <template #body="{data}">
                  <div v-if="data.visit" class="text-success">
                    Присутствует
                  </div>
                  <div v-else class="text-danger">
                    Не присутствует
                  </div>
                </template>
              </Column>

            </DataTable>

          </div>
          <div v-else>Список студентов пуст</div>
        </div>
      </section>
      <!-- end Students section -->


    </div>
  </div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"
  import httpClient from "@/services/http.service"


  export default {
    name: "TeacherAttendance",
    data() {
      return {
        loading: true,
        interval: null,
        QRPath: null,
        students: [],
        loadingStudents: true,
      }
    },
    methods: {
      ...mapMutations(['SET_ERROR']),
      async getQR() {
        try {
          const {status, data} = await httpClient.get('student_data/student-visiting-journal/get-qr-for-lessons')
          if (status === 200) {
            this.QRPath = data
          }
        } catch (e) {
          this.SET_ERROR(e)
        }
      },
      async getStudents() {
        this.loadingStudents = true
        try {
          const {status, data} = await httpClient.get('student_data/student-visiting-journal/get-students')
          if (status === 200) {
            this.students = data.students || []
          }
        } catch (e) {
          this.SET_ERROR(e)
        }
        this.loadingStudents = false
      },
    },
    async mounted() {
      await this.getQR()

      this.interval = setInterval(() => {
        this.getQR()
      }, 20000)

      await this.getStudents()
      this.loading = false
    },
    beforeUnmount() {
      clearInterval(this.interval)
    },
  }
</script>